<template>
  <div class="project">
    <panav :color='color' :bgColor='bgColor' />
    <main>
      <ul>
        <li v-for="item in list" :key="item.id" @click="news(item.id)">
          <div class="left">
            <p>{{item.createtime_text}}</p>
          </div>
          <div class="right">
            <div>
              <p>{{item.name}}</p>
              <p>{{item.desc}}</p>
            </div>
          </div>
        </li>
      </ul>
    </main>
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>

<script>
import panav from "@/components/pc/nav.vue";
import horserace from "@/components/pc/horserace.vue";

export default {
  components: {
    panav,
    horserace,
  },
  data() {
    return {
      color: "#64471a",
      list: [],
      noticeColor: "#FFD7D6",
      background: "#64471a",
      bgColor: "#ffd7d6",
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    async getNews() {
      const res = await this.axios("/api/index/news");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.list = data;
        console.log(this.list);
      }
    },
    news(id) {
      console.log(id);
      this.$router.push(`/new?id=${id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.project {
  background: #ffd7d6;
  min-height: 100vh;
  main {
    padding-top: 0.72rem;
    padding-bottom: 1rem;
    ul {
      li {
        padding: 0.1rem;
        cursor: pointer;
        border-bottom: 2px solid #64471a;
        display: flex;
        font-size: 0.27rem /* 24/100 */;
        color: #64471a;
        font-family: Antipol, GLEXME;
        .left {
          width: 50%;
          padding-left: 0.4rem /* 50/100 */;
        }
        .right {
          width: 40%;
          position: relative;
          overflow: hidden;
          // div {
          //   position: absolute;
          //   top: 50%;
          //   transform: translate(0, -50%);
          // }
        }
      }
      li:hover {
        color: #fff;
      }
    }
  }
}
</style>

